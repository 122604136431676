export function formatNumber(
  value: number | string,
  maximumFractionDigits = 2
): string {
  const num = typeof value == "string" ? parseFloat(value) : value
  return isNaN(num)
    ? value.toString()
    : Intl.NumberFormat("he-IL", {
        maximumFractionDigits,
      }).format(num)
}

export const formatPhone = (number: string) =>
  // number.replace(/^(\d{2,3})(\d{7})$/, "$1-$2")
  number

export function formatCurrency(value: number, maximumFractionDigits = 0) {
  return Intl.NumberFormat("he-IL", {
    maximumFractionDigits,
  }).format(value)
}

const startsWithHebrewRegex = /^[\u0590-\u05fe]/
export function getDirection(unit: string) {
  return startsWithHebrewRegex.test(unit) ? "rtl" : "ltr"
}
